/* src/app/world-ship-upload/world-ship-upload.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.world-ship-upload,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.world-ship-upload {
  background-color: white;
}
.world-ship-upload .form-header-wrapper,
.world-ship-upload .form-footer-wrapper {
  background-color: #f0f0ef;
}
.world-ship-upload .form-footer-wrapper .ups-page-header {
  border-top: solid 2px #004791;
  border-bottom: none;
}
.world-ship-upload .form-header,
.world-ship-upload .form-footer,
.world-ship-upload .form-body {
  max-width: 75rem;
  margin: 0 auto;
}
.world-ship-upload .form-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 2rem;
}
@media screen and (min-width: 48rem) {
  .world-ship-upload .form-controls {
    flex-direction: row;
  }
}
.world-ship-upload .form-controls .form-title {
  font-size: 1.5em;
  color: rgba(36, 36, 36, 0.87);
}
.world-ship-upload .form-controls .form-actions {
  position: relative;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
@media screen and (min-width: 48rem) {
  .world-ship-upload .form-controls .form-actions {
    margin-left: auto;
  }
}
.world-ship-upload .form-controls .form-actions button {
  width: 10rem;
  margin: 1rem;
}
@media screen and (min-width: 48rem) {
  .world-ship-upload .form-controls .form-actions button {
    margin: 0 1rem;
  }
}
.world-ship-upload .form-body {
  margin-top: 30px;
}
.world-ship-upload .form-body .notice-title {
  margin-bottom: 1em;
}
.world-ship-upload .form-body .instruction-link {
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  margin: 2rem 0;
}
.world-ship-upload .form-body .reminder {
  margin-top: 5rem;
}
.world-ship-upload .form-body-ForEU {
  padding: 0rem 2rem;
}
.world-ship-upload .form-body-ForEU .notice-title {
  margin-bottom: 1em;
}
.world-ship-upload .form-body-ForEU .instruction-link {
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  margin: 0rem 0;
}
.world-ship-upload .form-body-ForEU .reminder {
  margin-top: 5rem;
}
.world-ship-upload .select-reference-option {
  margin-top: 45px;
}
.world-ship-upload .browse-worldship-export-file {
  margin-top: 45px;
}
.world-ship-upload .upload-file {
  width: 150px;
  margin-top: 81px;
}
.world-ship-upload .file-selector-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
}
.world-ship-upload .file-selector-wrapper upsc-file-selector {
  margin-right: 1em;
}
.world-ship-upload .file-selector-wrapper .file-name {
  width: 15em;
  color: #009cbd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.world-ship-upload .upload-link {
  line-height: 2.35em;
  box-shadow: none;
  border: solid thin #00857d;
  background-color: #00857d;
  color: white;
  border-radius: 4px;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 1em;
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 45px;
  text-decoration: none;
}
.world-ship-upload .mat-mdc-radio-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.world-ship-upload .mat-mdc-radio-group .mat-mdc-radio-button {
  margin-bottom: 0.5em;
}
.world-ship-upload .pd {
  padding: 0rem 2rem;
}
::ng-deep .mat-mdc-tab-body-content {
  overflow: revert !important;
}
.question-container {
  flex-direction: row;
  width: 880px;
  margin-top: 60px;
}
.question-container h4 {
  margin-bottom: 0px;
  font-size: 14.918px;
  color: #121212;
  font-family: Roboto;
  font-size: 14.918px;
  font-weight: 500;
}
.question-container ::ng-deep .mdc-button__label {
  font-size: 14px;
  color: #5F5753;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}
.question-container hr {
  border: 1px solid #DFDBD7;
  margin-top: 20px;
}
.question-container span {
  font-size: 14px;
}
.question-container span a {
  color: #0662BB;
}
.question-container .download-butttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 15px;
}
.question-container .key {
  width: 907px;
  height: 1px;
  margin-top: 30px;
  background: #DFDBD7;
}
/*# sourceMappingURL=world-ship-upload.component.css.map */
